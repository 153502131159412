<template>
  <feedback-section :title="title"
                    :icon="'invoice'"
                    :button-description="lastUpdatedText"
                    :progress-active="progressActive"
                    class="invoice-info-section"
                    @save="save">
    <section class="invoice-info-section__sections-wrapper">
      <component :is="section.component"
                 v-for="(section, index) in sections"
                 :key="index"
                 :params="section.params" />
    </section>
  </feedback-section>
</template>

<script>
  import {mapGetters, mapState} from 'vuex';
  import helpers from '@/helpers';
  import FeedbackSection
    from '@/components/assignment_components/one_assignment/interactive_info/pt_feedback/FeedbackSection';
  import BookingReferenceSection from '@/components/booking_components/parts/BookingReferenceSection';
  import CaseNumberSection from '@/components/booking_components/parts/CaseNumberSection';
  import ClientSection from '@/components/booking_components/parts/ClientSection';
  import PaymentMethodSection from '@/components/booking_components/parts/PaymentMethodSection';
  import BookerSection from '@/components/booking_components/parts/BookerSection';
  import DepartmentAttendersSection from '@/components/booking_components/parts/DepartmentAttendersSection';
  import PostingCommon from '@/prototypes/PostingCommon';

  export default {
    components: {
      'feedback-section': FeedbackSection,
      'booking-reference-section': BookingReferenceSection,
      'booker-section': BookerSection,
      'case-number-section': CaseNumberSection,
      'client-section': ClientSection,
      'payment-method-section': PaymentMethodSection,
      'department-attenders-section': DepartmentAttendersSection
    },
    extends: PostingCommon,
    props: {
      location: {
        type: String,
        default: 'translation_feedback'
      }
    },
    data() {
      return {
        progressActive: false
      };
    },
    computed: {
      ...mapGetters('FeedbackStore', [
        'bookerDefaultBookingReference',
        'bookerDefaultPaymentBookingReference'
      ]),
      ...mapState('FeedbackStore', {
        lastUpdatedData: ({lastUpdatedData}) => lastUpdatedData?.job_updated_invoicing || lastUpdatedData?.order_updated_invoicing,
        lastUpdatedJobData: ({lastUpdatedData}) => lastUpdatedData?.job_updated || lastUpdatedData?.order_updated
      }),
      ...mapGetters('PostingNotifierStore', [
        'showBookingReferenceSection',
        'showCaseNumberSection',
        'showClientSection',
        'showPaymentMethod',
        'showBookerSection',
        'showDepartmentAttendersSection',
      ]),
      isOnProjectFeedBack() {
        return this.location === 'project_feedback';
      },
      lastUpdatedText() {
        return helpers.feedbackInfo.lastUpdatedText({
          context: this,
          lastUpdatedSectionData: this.lastUpdatedData,
          lastUpdatedJobData: this.lastUpdatedJobData,
        });
      },
      title() {
        return this.$gettext('Invoice');
      },
      sectionsListForTranslationFeedback() {
        return [{
          component: 'booking-reference-section',
          condition: this.showBookingReferenceSection,
          params: {
            bookerDefaultBookingReference: this.bookerDefaultBookingReference,
            bookerDefaultPaymentBookingReference: this.bookerDefaultPaymentBookingReference
          }
        }, {
          component: 'case-number-section',
          condition: this.showCaseNumberSection
        }, {
          component: 'client-section',
          condition: this.showClientSection
        }, {
          component: 'payment-method-section',
          condition: this.showPaymentMethod
        }];
      },
      sectionsListForProjectFeedback() {
        return [{
          component: 'booker-section',
          condition: this.showBookerSection
        }, {
          component: 'department-attenders-section',
          condition: this.showDepartmentAttendersSection
        }, {
          component: 'booking-reference-section',
          condition: this.showBookingReferenceSection,
          params: {
            bookerDefaultBookingReference: this.bookerDefaultBookingReference,
            bookerDefaultPaymentBookingReference: this.bookerDefaultPaymentBookingReference
          }
        }, {
          component: 'client-section',
          condition: this.showClientSection
        }, {
          component: 'case-number-section',
          condition: this.showCaseNumberSection
        }, {
          component: 'payment-method-section',
          condition: this.showPaymentMethod
        }];
      },
      sections() {
        switch (this.location) {
          case 'translation_feedback':
            return this.sectionsListForTranslationFeedback.filter((section) => section.condition) || [];
          case 'project_feedback':
            return this.sectionsListForProjectFeedback.filter((section) => section.condition) || [];
        }
      }
    },
    methods: {
      save() {
        switch (this.location) {
          case 'translation_feedback':
            return this.saveTranslationFeedback();
          case 'project_feedback':
            return this.saveProjectFeedback();
        }
      },
      saveTranslationFeedback() {
        const jobId = this.$route.params.id;
        const PostingStore = this.$store.state.PostingStore;

        this.progressActive = true;
        this.$store.commit('PostInterpretationStore/setTrackType', 'job_updated_invoicing');
        this.$store.dispatch('PostInterpretationStore/editJobPartially', {
          bookingRef: PostingStore.BookingRefStore.bookingReference,
          paymentBookingRef: PostingStore.BookingRefStore.paymentBookingReference,
          paymentMethodId: PostingStore.PaymentMethodStore.paymentMethod,
          caseNumber: PostingStore.CaseNumberStore.caseNumber,
          clientName: PostingStore.ClientStore.clientName,
          clientId: PostingStore.ClientStore.clientId
        })
          .then((jobObj) => {
            this.$store.commit('OneAssignmentStore/setStoreJob', jobObj);
            this.$store.dispatch('FeedbackStore/getLastUpdatedData', {jobId}).then(() => {
              this.progressActive = false;
            });
          })
          .catch((error) => {
            this.progressActive = false;
            this.errorHandlingFunc(error);
          });
      },
      validate() {
        this.$store.commit('ErrorsStore/removeAllErrors');
        return this.validateBookingReferenceSection() & this.validateCaseNumberSection();
      },
      saveProjectFeedback() {
        if (this.validate()) {
          const PostingStore = this.$store.state.PostingStore;
          const orderId = this.$route.params.id;
          this.progressActive = true;
          this.$store.commit('PostTranslationProjectStore/setTrackType', 'order_updated_invoicing');
          this.$store.dispatch('PostTranslationProjectStore/editProjectPartially', {
            bookingRef: PostingStore.BookingRefStore.bookingReference,
            paymentBookingRef: PostingStore.BookingRefStore.paymentBookingReference,
            paymentMethodId: PostingStore.PaymentMethodStore.paymentMethod,
            caseNumber: PostingStore.CaseNumberStore.caseNumber,
            clientName: PostingStore.ClientStore.clientName,
            clientId: PostingStore.ClientStore.clientId
          })
            .then(() => {
              this.$store.dispatch('FeedbackStore/getProjectLastUpdatedData', {orderId}).then(() => {
                this.progressActive = false;
              });
            })
            .catch(() => {
              this.progressActive = false;
              this.$store.commit('ModalStore/setModal', {
                component: 'error-modal'
              });
            });
        }
      },
      errorHandlingFunc(error) {
        this.$store.commit('ModalStore/setModal', {
          component: 'assignment-info-edit-error-modal',
          classes: ['sk-modal--new'],
          data: {
            title: this.$gettext('Validation error'),
            errors: error
          }
        });
      }
    },
    created() {
      // if it's on invoice is on project feedback hide attenders sections
      if (this.isOnProjectFeedBack) this.$store.dispatch('PostingStore/DepartmentAttendersStore/changeShowAttenders', false);
    },
  };
</script>

<style scoped>
  .invoice-info-section__sections-wrapper {
    margin: 0 -15px;
  }
</style>
